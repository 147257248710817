








































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import businessStoreModule from "@/store/modules/business";
import { Business, Role } from "@/types";

const { mapActions: businessActions } =
  createNamespacedHelpers("BUSINESS_STORE");
export default Vue.extend({
  name: "BusinessSettings",
  data: () => ({
    timezone: "",
    email: "",
    placeId: "",
    apiKey: "",
    timezoneList: [
      "Africa/Nairobi",
      "Pacific/Norfolk",
      "Australia/Lord_Howe",
      "Antarctica/Macquarie",
      "Australia/Sydney",
      "Australia/Hobart",
      "Australia/Melbourne",
      "Australia/Brisbane",
      "Australia/Lindeman",
      "Australia/Broken_Hill",
      "Australia/Adelaide",
      "Australia/Darwin",
      "Australia/Eucla",
      "Australia/Perth",
      "Indian/Christmas",
      "Antarctica/Davis",
      "Indian/Cocos",
      "Antarctica/Mawson",
    ],
  }),
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
    business(): Business {
      return this.$store.getters["BUSINESS_STORE/getBusiness"](
        this.role?.business._id
      );
    },
  },
  created() {
    this.getBusiness();
  },
  methods: {
    ...businessActions(["updateBusiness", "fetchBusiness"]),
    save() {
      this.updateBusiness({
        id: this.role.business?._id,
        business: {
          timezone: this.timezone,
          email: this.email,
          placeId: this.placeId,
          apiKey: this.apiKey,
        },
      }).then(() => {
        this.getBusiness();
        this.$store.dispatch(
          "setToast",
          {
            title: "Success",
            type: "success",
            text: "Business updated",
          },
          { root: true }
        );
      });
    },
    getBusiness() {
      if (this.role) {
        this.fetchBusiness(`?businessId=${this.role.business._id}`).then(
          (biz) => {
            localStorage.setItem("tz", biz.timezone);
            if (biz) {
              this.timezone = biz.timezone;
              this.email = biz.email;
              this.placeId = biz.placeId;
              this.apiKey = biz.apiKey;
            }
          }
        );
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("BUSINESS_STORE")) {
      this.$store.registerModule("BUSINESS_STORE", businessStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("BUSINESS_STORE");
  },
});
